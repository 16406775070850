<script setup lang="ts">
    import type { AssetData, Style } from '~/@types/cms';

    interface LookbookCollageProps {
        title?: string;
        copy?: string;
        asset1?: AssetData;
        asset2?: AssetData;
        asset3?: AssetData;
        link?: Link;
        anchor?: string;
        style?: Style;
        codename?: string;
    }
    const props = defineProps<LookbookCollageProps>();

    const { textColorClass, textColorStyle, buttonColorClass, buttonColorStyle, buttonLabelColorClass, buttonLabelColorStyle } = useStyleCustom(
        props.style,
    );

    const asset3Style = computed(() => {
        const type = props.style?.render ?? 'type1';
        return type === 'type2' ? 'lg:absolute lg:bottom-14 lg:right-0 lg:z-10 lg:max-w-xl lg:will-change-transform' : null;
    });
</script>
<template>
    <section
        :id="codename"
        class="mol-lookbook-collage relative py-10 lg:my-10 lg:py-[10vh]">
        <atm-grid
            full-width
            no-margin>
            <div class="col-span-2 md:col-span-12 lg:col-span-6">
                <div
                    v-if="asset1"
                    class="lb-collage-asset-1">
                    <lazy-atm-image
                        :data="asset1"
                        preset="3to2"
                        sizes="100vw md:1024px lg:1264px" />
                </div>

                <atm-grid
                    full-width
                    class="my-8 lg:my-14">
                    <div
                        :class="[textColorClass, 'relative col-span-2 flex scroll-mt-8 flex-col gap-6 md:col-span-12 md:max-w-md lg:mx-auto']"
                        :id="anchor"
                        :style="textColorStyle">
                        <lazy-atm-heading
                            v-if="title"
                            level="h3">
                            <renderer-html :content="woomTextFormat(title)" />
                        </lazy-atm-heading>

                        <div
                            v-if="copy"
                            class="flex flex-col gap-4">
                            <renderer-html :content="woomTextFormat(copy)" />
                        </div>

                        <lazy-atm-link
                            v-if="link?.label"
                            :link="link"
                            styling="solid-invert"
                            :class="['mt-2', buttonColorClass, buttonLabelColorClass]"
                            :style="[buttonColorStyle, buttonLabelColorStyle]">
                            <renderer-html :content="woomTextFormat(link?.label)" />
                        </lazy-atm-link>
                    </div>
                </atm-grid>
            </div>
            <div class="col-span-2 flex flex-col items-end md:col-span-12 lg:col-span-6">
                <div
                    v-if="asset2"
                    class="lb-collage-asset-2 w-full">
                    <lazy-atm-image
                        :data="asset2"
                        class="w-full"
                        preset="2to3"
                        sizes="100vw md:1024px lg:1264px" />
                </div>
                <div
                    v-if="asset3"
                    :class="['lb-collage-asset-3 w-full', asset3Style]">
                    <lazy-atm-image
                        :data="asset3"
                        class="w-full"
                        sizes="100vw md:1024px" />
                </div>
            </div>
        </atm-grid>
    </section>
</template>

<style scoped>
    @screen lg {
        .lb-collage-asset-1,
        .lb-collage-asset-2,
        .lb-collage-asset-3 {
            transform: translateY(var(--translate));
            will-change: transform;
        }
    }
</style>
